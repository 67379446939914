import * as React from 'react';
import { ListDandD } from '../components/formula/listaDandD';
import LowStockWidgetComponent from '../components/materia_prima/widget/LowStockWidgetComponent';
import { OdenFabricacionEnCursoList } from '../components/orden/widgets/OrdenFabricacionEnCursoList/OrdenFabricacionEnCursoListComponent';

export const HomePage = () => {
  const test = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'];
  const a = [
    {
      id: 1,
      formulaid: 2,
      materiaprima: { nombre: 'dsadsasadas' },
      pausa: false,
      cantidad: 4,
      observacion: 'obnskdlsalsak',
      orden: 1
    },
    {
      id: 2,
      formulaid: 1,
      materiaprima: { nombre: 'dsadsa sadas' },
      pausa: false,
      cantidad: 1,
      observacion: 'obnskdlsalsak',
      orden: 1
    }
  ];
  return (
    <>
      <div className="pageHeader">
        <h2>Panel de información</h2>
      </div>
      <div className="widgetRow">
        <OdenFabricacionEnCursoList />
        <LowStockWidgetComponent />
      </div>
    </>
  );
};
