import React, { useEffect } from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';

//store
import * as AuthStore from '../../stores/authentication';

export const AuthorizeRoute = (props: any) => {
  const AuthenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  const { component: Component, ...rest } = props;
  var link = document.createElement('a');
  link.href = props.path;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `/${
    process.env.REACT_APP_LOGINPAGE
  }?returnUrl=${encodeURI(window.location.href)}`;

  return !AuthenticationState?.isAuthenticate ? (
    <Redirect to={redirectUrl} />
  ) : (
    <Route
      {...rest}
      render={(props) => {
        if (AuthenticationState?.isAuthenticate) {
          return <Component {...rest} {...props} />;
        } else {
          return <Redirect to={redirectUrl} />;
        }
      }}
    />
  );
};

export default AuthorizeRoute;
