import { MenuItem } from './MenuItem';
import { MenuItems } from './MenuItems';

// import styles
import './MenuComponent.css';
import { NavLink } from 'react-router-dom';

export interface IProps {
  Group: number;
}

export const MenuComponent = (props: IProps) => {
  return (
    <div className="menuContent">
      {MenuItems.filter(
        (menuItem: MenuItem) => menuItem.Group === props.Group
      ).map((option: MenuItem) => (
        <NavLink
          to={option.location}
          className="menuItem"
          activeClassName="active"
        >
          {option.icon()}
          <label>{option.title}</label>
        </NavLink>
      ))}
    </div>
  );
};
