import { Reducer, Action } from 'redux';
import { AppThunkAction } from '.';
import { SaveOrden } from '../components/orden/saveOrden';
import { SaveOrdenFull } from '../components/orden/saveOrdenFull';
import { AdditionalRawMaterial } from '../model/AdditionalRawMaterial';
import { ErrorType } from '../model/FetchError';
import { OrderVerification } from '../model/finalizarOrden';
import { Orden } from '../model/orden';
import { IResult } from '../model/Result';

//actions 
import * as Actions from '../redux/Actions/OrderActions';

// Functions
import * as Functions from '../redux/Functions/Commons';

// Actions
interface finalizeOrder {
  type: 'FINALIZE_ORDER';
}

interface finalizedOrder {
  type: 'FINALIZED_ORDER';
  StatusOk: boolean;
  Errors: any;
  order?: Orden;
}

interface FailFinalizeOrder {
  type: 'FAIL_FINALIZE_ORDER';
  Error: ErrorType;
}

interface AddAdditionalRawMaterial {
  type: 'ADD_ADDITIONAL_RAW_MATERIAL';
  orderid: number;
  AdditionalRawMaterial: AdditionalRawMaterial;
}

interface AddedAdditionalRawMaterial {
  type: 'ADDED_ADDITIONAL_RAW_MATERIAL';
  StatusOk: boolean;
  Errors: any;
  AdditionalRawMaterial: AdditionalRawMaterial;
}

interface getAllOrdenes {
  type: 'GET_ALL_ORDENES';
  ordenes: Orden[];
  pagination?: {};
  finalized?: boolean
}

interface RequestOrderAction {
  type: 'REQUEST_ORDER_ACTION';
  ordenId: number;
}

interface ReceiveOrderAction {
  type: 'RECEIVE_ORDER_ACTION';
  orden: Orden;
}

interface CreateNewOrderAction {
  type: 'CREATE_NEWORDER_ACTION';
  orden: Orden;
}

interface CleanNewOrderAction {
  type: 'CLEAN_NEWORDER_ACTION';
}

interface saveOrdenFabricacion {
  type: 'SAVE_ORDENES';
  orden: Orden;
}
type KnownAction =
  | getAllOrdenes
  | finalizeOrder
  | finalizedOrder
  | AddAdditionalRawMaterial
  | AddedAdditionalRawMaterial
  | CreateNewOrderAction
  | CleanNewOrderAction
  | RequestOrderAction
  | FailFinalizeOrder
  | ReceiveOrderAction
  | Actions.Order_Cancel_Success_Action;

export const actionCreators = {
  getAllOrdenes:
    (
      pageNumber?: number, 
      pageSize?: number, 
      finished?: boolean, 
      text?: string,
      filterOn?: string,
      orderDate?: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token = state.authentication?.authenticatedUser?.jwToken;

      if (pageNumber !== undefined && pageSize !== undefined) {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT +
            'v1/ordenesfabricaciones?PageNumber=' +
            pageNumber +

            '&PageSize=' +
            pageSize + 

            '&Finished=' + 
            finished +

            '&Text=' +
            (text ? text : '') +
            
            '&FilterOn=' +
            (filterOn ? filterOn : '' ) +
            
            '&OrderDate=' +
            (orderDate ? orderDate : '' ),
            
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
              },
              method: 'GET'
            }
        )
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            const { pageNumber, pageSize, totalPages, totalRecords} = data || {};
            dispatch({
              type: 'GET_ALL_ORDENES',
              ordenes: (data.data || []),
              pagination: {
                pageNumber,
                pageSize,
                totalPages,
                totalRecords
              },
              finalized: finished,
              
            });
          });
      } else {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT + 'v1/ordenesfabricaciones'
        )
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            console.log(data.data);
            dispatch({
              type: 'GET_ALL_ORDENES',
              ordenes: data.data,
              pagination: {},
              finalized: finished
            });
          });
      }
    },

  saveOrden:
    (orden: SaveOrden): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token = state.authentication?.authenticatedUser?.jwToken;

      await fetch(
        process.env.REACT_APP_API_ENDPOINT + 'v1/ordenesfabricacionesfull',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          },
          method: 'POST',
          body: JSON.stringify(orden)
        }
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          dispatch({ type: 'CREATE_NEWORDER_ACTION', orden: data.data });
        });
    },
  saveOrdenNew:
    (orden: SaveOrdenFull): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token = state.authentication?.authenticatedUser?.jwToken;

      await fetch(
        process.env.REACT_APP_API_ENDPOINT + 'v1/ordenesfabricacionesfull',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          },
          method: 'POST',
          body: JSON.stringify(orden)
        }
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          dispatch({ type: 'CREATE_NEWORDER_ACTION', orden: data.data });
          console.log(data.data);
        });
    },
  cleanNewOrder:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: 'CLEAN_NEWORDER_ACTION' });
    },
  finalizarOrden:
    (id: number, orden: OrderVerification): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token = state.authentication?.authenticatedUser?.jwToken;

      dispatch({
        type: 'FINALIZE_ORDER'
      });
      await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          'v1/ordenesfabricaciones/' +
          id +
          '/finalizar',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          },
          method: 'PUT',
          body: JSON.stringify(orden)
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<Orden>>;
          }
        })
        .then((data: IResult<Orden>) => {
          dispatch({
            type: 'FINALIZED_ORDER',
            StatusOk: true,
            order: data.data,
            Errors: null
          });
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_FINALIZE_ORDER',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    },
  SetOrder:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token = state.authentication?.authenticatedUser?.jwToken;

      dispatch({ type: 'REQUEST_ORDER_ACTION', ordenId: id });
      await fetch(
        process.env.REACT_APP_API_ENDPOINT + 'v1/ordenesfabricaciones/' + id,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          },
          method: 'GET'
        }
      )
        .then((response) => response.json() as Promise<IResult<Orden>>)
        .then((data) => {
          dispatch({
            type: 'RECEIVE_ORDER_ACTION',
            orden: data.data
          });
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  AddAdditionalRawMaterial:
    (
      AdditionalRawMaterial: AdditionalRawMaterial
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token = state.authentication?.authenticatedUser?.jwToken;

      dispatch({
        type: 'ADD_ADDITIONAL_RAW_MATERIAL',
        orderid: AdditionalRawMaterial.ordenfabricacionid,
        AdditionalRawMaterial: AdditionalRawMaterial
      });
      await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          'v1/ordenesfabricaciones/' +
          AdditionalRawMaterial.ordenfabricacionid +
          '/adicionales',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          },
          method: 'POST',
          body: JSON.stringify(AdditionalRawMaterial)
        }
      )
        .then(
          (response) =>
            response.json() as Promise<IResult<AdditionalRawMaterial>>
        )
        .then((data) => {
          dispatch({
            type: 'ADDED_ADDITIONAL_RAW_MATERIAL',
            Errors: null,
            StatusOk: true,
            AdditionalRawMaterial: data.data
          });
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
};
export interface Pagination {
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalRecords?: number;
}

export interface OrdenesState {
  isLoading: boolean;
  ordenes?: Orden[];
  orden?: Orden;
  newOrderGenerated?: Orden;
  StatusOk: boolean;
  Error: ErrorType | undefined;
  isFinalizingOrder: boolean;
  isFinalizedSuccess: boolean | undefined;
  FailFinalizeOrder: boolean;
  pagination?: Pagination;
}

const unloadedState: OrdenesState = {
  isLoading: false,
  StatusOk: true,
  Error: undefined,
  isFinalizedSuccess: undefined,
  isFinalizingOrder: false,
  FailFinalizeOrder: false,
  pagination: {}
};

export const reducer: Reducer<OrdenesState> = (
  state: OrdenesState | undefined,
  incomingAction: Action
): OrdenesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'GET_ALL_ORDENES':
      state.ordenes = state.ordenes?.filter(x => x.finalizado != action.finalized);

      const OrdenesArray = state.ordenes ? state.ordenes.concat(action.ordenes) : action.ordenes.sort((a: Orden, b: Orden) => a.lote - b.lote);

      const uniqueIds = new Set();

      const unique = OrdenesArray.filter(orden => {
        const isDuplicate = uniqueIds.has(orden.id);
      
        uniqueIds.add(orden.id);
      
        if (!isDuplicate) {
          return true;
        }
      
        return false;
      });  

      
      return {
        ...state,
        ordenes: unique,
        pagination: action?.finalized ? action?.pagination : {},
        isLoading: false
      };
    case 'FINALIZE_ORDER':
      return {
        ...state,
        isFinalizingOrder: true,
        isFinalizedSuccess: undefined,
        FailFinalizeOrder: false,
        Error: undefined
      };
    case 'FINALIZED_ORDER':
      return {
        ...state,
        isFinalizingOrder: false,
        isFinalizedSuccess: true,
        FailFinalizeOrder: false,
        ordenes:
          (action.StatusOk && state.ordenes && action.order
            ? [
                ...state.ordenes.filter((order: Orden) =>
                  order.id === action.order?.id ? false : true
                ),
                action.order
              ]
            : state.ordenes)?.sort((a: Orden, b: Orden) => b.id - a.id),
        orden: action.order,
        Error: undefined
      };
    case 'ORDER_CANCEL_SUCCESS':
      var orderCanceled = state.ordenes?.find((order: Orden) => order.id === action.orderId);
      var currentorderCanceled = {...state.orden, cancelado: true, finalizado: true }
      if(orderCanceled){
        orderCanceled = {...orderCanceled, cancelado: true, finalizado: true};
      }
      return {
        ...state,
        ordenes: orderCanceled ? [...state.ordenes?.filter((order: Orden) => order.id !== action.orderId) || [], orderCanceled] : state.ordenes,
        orden: currentorderCanceled ? currentorderCanceled as Orden : state.orden
      };
    case 'FAIL_FINALIZE_ORDER':
      return {
        ...state,
        isFinalizingOrder: false,
        isFinalizedSuccess: false,
        FailFinalizeOrder: true,
        Error: action.Error
      };
    case 'REQUEST_ORDER_ACTION':
      return {
        ...state,
        isLoading: true,
        StatusOk: true
      };
    case 'RECEIVE_ORDER_ACTION':
      return {
        ...state,
        isLoading: false,
        StatusOk: true,
        orden: action.orden
      };
    case 'CREATE_NEWORDER_ACTION':
      return {
        ...state,
        newOrderGenerated: action.orden
      };
    case 'CLEAN_NEWORDER_ACTION':
      return {
        ...state,
        newOrderGenerated: undefined
      };
    case 'ADDED_ADDITIONAL_RAW_MATERIAL':
      const currentOrden: Orden | undefined = state?.ordenes?.find(
        (order: Orden) =>
          order.id === action.AdditionalRawMaterial.ordenfabricacionid
            ? false
            : true
      );
      if (currentOrden) {
        currentOrden.lista_adicionales.push(action.AdditionalRawMaterial);
      }
      return {
        ...state,
        StatusOk: action.StatusOk,
        orden: currentOrden,
        isLoading: false,
        Error: action.Errors
      };
  }

  return state;
};
